import PropTypes from 'prop-types';
import { Drawer, Button, Grid } from '@mui/material';

SaveCancelDrawer.propTypes = {
  open: PropTypes.bool,
  text: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  cancelHandler: PropTypes.func,
  confirmHandler: PropTypes.func
};

export function SaveCancelDrawer(props) {
  const { open, text, cancelText, confirmText, cancelHandler, confirmHandler } = props;

  const configCancelButton = {
    onClick: cancelHandler,
    variant: 'outlined',
    fullWidth: true
  };
  const configConfirmButton = {
    onClick: confirmHandler,
    variant: 'contained',
    color: 'primary',
    fullWidth: true
  };
  return (
    <Drawer variant="persistent" anchor="bottom" open={open}>
      {text}
      <Grid
        container
        columnSpacing={3}
        rowSpacing={1}
        columns={{ xs: 6, sm: 12, md: 12, lg: 12 }}
        p={{ xs: 2, sm: 3 }}
      >
        <Grid item xs={12} sm={4} md={6} lg={8} style={{ color: '#0077b6' }}>
          <h4>{confirmText || 'Tienes cambios por guardar'}</h4>
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Button {...configCancelButton}>{cancelText || 'Descartar cambios'}</Button>
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Button {...configConfirmButton}>{confirmText || 'Guardar'}</Button>
        </Grid>
      </Grid>
    </Drawer>
  );
}

import { Grid, Typography } from '@mui/material';
import { Formik } from 'formik';

import { SaveCancelDrawer } from 'components/Form/SaveCancelDrawer';
import * as yup from 'yup';
import TextField from '../../Form/TextFieldWrapper';

const FORM_VALIDATION = yup
  .object()
  .shape({
    notes: yup.string()
  })
  .required();

export function UserOdontogramNotesForm(props) {
  return (
    <Formik
      initialValues={{ notes: props.initialValue || '' }}
      validationSchema={FORM_VALIDATION}
      onSubmit={(_values, { resetForm }) => {
        resetForm({ values: _values });
        props.onSubmit(_values.notes);
      }}
    >
      {({ dirty, submitForm, resetForm }) => (
        <>
          <Typography variant="h5" gutterBottom pt={3}>
            Notas del odontodiagrama
          </Typography>

          <Grid
            container
            rowSpacing={5}
            columnSpacing={3}
            columns={{ xs: 6, sm: 6, md: 12 }}
            p={{ xs: 2, sm: 3 }}
          >
            <Grid item xs={12}>
              <TextField name="notes" multiline rows={10} variant="outlined" />
            </Grid>
          </Grid>
          <SaveCancelDrawer open={dirty} cancelHandler={resetForm} confirmHandler={submitForm} />
        </>
      )}
    </Formik>
  );
}
